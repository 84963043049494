do ->
  fnSmoothScroll = ->
    'use strict'
    $('a[href^="#"]').smoothScroll()

  fnShowMore = (selector, shown) ->
    _$targets = $(selector)
    _shown    = shown || 2

    return false if _$targets.length == 0

    _$targets.each (idx, el)->
      $root = $(el)

      return false if $root.hasClass('show-more--init')

      $root.addClass('show-more--init')

      $trigger = $root.find(selector + '-trigger')
      $targets = $root.find(selector + '-targets > *')

      $hiddenTargets = $targets.slice(_shown)

      $hiddenTargets.css
        display: 'none'

      $trigger.click (ev)->
        ev.preventDefault()

        if $root.hasClass('is-show-more--open')
          $root.removeClass('is-show-more--open')
          $hiddenTargets.stop().slideUp()

          # TODO: ちょっと雑なのはわかってる
          moveTo = $(this).data('move-on-close')
          $('html,body').animate({ scrollTop: $('#' + moveTo).offset().top - 16 }) if moveTo

        else
          $root.addClass('is-show-more--open')
          $hiddenTargets.stop().slideDown ->
            return false unless !!$root.data('is-adjust') == true

            $targets.each (idx)->
              if idx % 2 == 1
                _curr = $targets[idx].offsetHeight
                _prev = $targets[idx-1].offsetHeight
                _h = if _curr > _prev then _curr else _prev
                $($targets[idx]).height(_h)
                $($targets[idx-1]).height(_h)


  fnProposalFolding = (selector, shown)->
    _shown = shown || 3
    klass  = 'is-proposal-service-fold'
    klass2 = 'is-proposal-service--open'
    $list = $(selector)

    $list.each (idx, el)->
      $el = $(el)
      $items = $el.find('.proposal-service')

      return true if $items.length <= 3

      $targets = $items
        .slice(_shown)
        .addClass(klass)

      $btn = $('<li class="proposal-service-more"></li>')

      $el.append($btn)

      $btn.bind 'click', (ev)->
        ev.preventDefault()
        if $el.hasClass(klass2)
          $targets.stop().slideUp()
          $el.removeClass(klass2)
        else
          $targets.stop().slideDown()
          $el.addClass(klass2)


  fnSlidePreload = ->
    'use strict'
    setTimeout ->
      mainImgs = []
      $slideImgs  = $('.slide-visual-container > img')
      slideLength = $slideImgs.size()

      loopImageLoader = (num) ->
        mainImgs[num] = new Image()
        mainImgs[num].onload = ->
          $slideImgs.eq(num)
          .addClass('show')
          .stop(true, false)
          .animate({'opacity':1}, 300)
          setTimeout ->
            num++
            if num < slideLength
              loopImageLoader(num)
          , 400
        mainImgs[num].src = $slideImgs.eq(num).attr('src')

      loopImageLoader(0)
    , 800


  fnSlideVisualAutoHeight = (base, target, ratio, min, max, fps)->
    'use strict'
    $base   = $(base)
    $target = $(target)

    return false unless $base or $target

    _ratio  = ratio || 0.8
    _min = min || 370
    _max = max || 550
    _fps = fps || 30

    _prevHeight = 0

    setInterval ->
      _baseHeight = $base.outerHeight()
      return false if _prevHeight == _baseHeight

      _prevHeight = _baseHeight
      _targetHeight = ~~(_baseHeight * _ratio)

      _targetHeight = if _targetHeight < _min then _min else _targetHeight
      _targetHeight = if _max < _targetHeight then _max else _targetHeight

      # IMPROVE: 本当はひつようないときに実行させたくない
      #return false if _min == _targetHeight || _targetHeight == _max

      $target.height(_targetHeight)
    , 1000 / _fps


  fnSlideVisualScroll = (selector, fps, speed)->
    'use strict'
    $mainVisual = $(selector)

    return false if $mainVisual.length == 0

    $mainVisualContainer = $mainVisual.find('.slide-visual-container')

    $mainVisualContainer.append($mainVisualContainer.html())
    $mainVisualImages = $mainVisualContainer.find('img')

    _fps   = fps   || 24
    _speed = speed || 1
    _x = 0

    setInterval ->
      _w = $mainVisualImages[0].offsetWidth
      if Math.abs(_x) > _w
        _x = 0
        _$first = $mainVisualContainer.find('img').slice(0, 1)
        $mainVisualContainer.append(_$first)

      $mainVisualContainer[0].style.marginLeft = (_x -= _speed) + 'px'
    , (1000 / _fps)


  fnBannerVisualAdjust = (selector) ->
    'use strict'
    $root = $(selector)
    $img  = $root.find('img')

    return false if $root.length == 0 || $img.length == 0

    # TODO: onloadが発生しない…？
    #$img.one 'load', ->
    #  $img.fadeIn()

    setTimeout ->
      $img.fadeIn()
    , 450

    setInterval ->
      _offset = ($img.outerWidth() - $root.outerWidth()) / 2
      $img.css({ marginLeft: _offset * -1 })
    , (1000 / 30)

  $ ->
    fnSmoothScroll()
    fnShowMore('.js-show-more', 2)
    fnProposalFolding('.proposal-services')

    fnSlidePreload()
    fnSlideVisualScroll('.js-slide-visual', 45)
    fnSlideVisualAutoHeight(window, '.js-slide-visual-container', 0.25, 158, 238)
    fnBannerVisualAdjust('.js-banner-visual')
